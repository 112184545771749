// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-der-mk-js": () => import("./../../../src/pages/der-mk.js" /* webpackChunkName: "component---src-pages-der-mk-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mitgliedsbuende-js": () => import("./../../../src/pages/mitgliedsbuende.js" /* webpackChunkName: "component---src-pages-mitgliedsbuende-js" */),
  "component---src-pages-satzung-js": () => import("./../../../src/pages/satzung.js" /* webpackChunkName: "component---src-pages-satzung-js" */)
}

